<template>
  <div class="Details">
    <div class="bigImg"></div>
    <div class="dTop">
      <tit-vue :titleContent="changeTitle.titleTxt1"></tit-vue>
      <p class="titP">
        <span>以下图表显示了本平台客户的持仓数据，包括多头与空头之比例、持仓比例。</span>
        <span>
          数据信息代表了目前交易的趋势与方向。客户可参考这些交易数据，从而制定个人的交易策略。
        </span>
      </p>
      <div class="bigBox">
        <div class="echartsBox">
          <img src="../../static/details/1.png" alt="">
          <div class="sjBox">
            <d-echarts v-if="topEcharts.length>0" :msg="topEcharts[0]"></d-echarts>
          </div>
          <p v-if="topEcharts.length>0">数据最新更新时间：&nbsp; {{topEcharts[0].strLastUpdate}}</p>
        </div>
        <div class="echartsBox">
          <img src="../../static/details/2.png" alt="">
          <div class="sjBox">
            <d-echarts v-if="topEcharts.length>0" :msg="topEcharts[1]"></d-echarts>
          </div>
          <p v-if="topEcharts.length>0">数据最新更新时间：&nbsp; {{topEcharts[1].strLastUpdate}}</p>
        </div>
      </div>
    </div>
    <div class="dTop dBottom">
      <tit-vue :titleContent="changeTitle.titleTxt2"></tit-vue>
      <div class="bigBox">
      <div class="echartsBox">
        <img src="../../static/details/3.png" alt="">
        <div class="sjBox">
          <deta-echarts-vue v-if="echartDatabottom !== '' " :msg='bottomEharts[0]'></deta-echarts-vue>
        </div>
        <p>数据最新更新时间：&nbsp; {{echartDatabottom}}</p>
      </div>
      <div class="echartsBox">
        <img src="../../static/details/4.png" alt="">
        <div class="sjBox">
          <deta-echarts-vue v-if="echartDatabottom !=='' " :msg='bottomEharts[1]'></deta-echarts-vue>
        </div>
        <p>数据最新更新时间：&nbsp; {{echartDatabottom}}</p>
      </div>
    </div>
    </div>
  </div>
</template>
<script>
  import TitVue from './index/tit.vue';
  import DEcharts from './details/dEcharts.vue';
  import DetaEchartsVue from './details/detaEcharts.vue';
  import {datacenterGoldSilv,datacenterGoldSilverScale} from '../../api/info';
  export default {
    name: 'Details',
    metaInfo: {  
      title: '红狮金业APP,现货黄金白银投资专业软件,伦敦金伦敦银靠谱平台,黄金交易红狮启富APP',  
      meta: [  
        { name: 'keywords', content: '红狮金业,红狮启富,黄金交易平台,贵金属交易软件,贵金属投资APP,贵金属平台,现货黄金开户,现货白银,正规靠谱平台。' },  
        { name: 'description', content: '红狮金业APP是一款稳定安全的交易软件，专注于为客户提供24小时全球金融产品在线交易，包括现货黄金、现货白银、贵金属等产品的开户、投资交易服务。' }  
      ]  
    },
    components:{
      TitVue,
      DEcharts,
      DetaEchartsVue
    },
    data () {
      return {
        changeTitle:{
          titleTxt1:['持仓比例',false],
          titleTxt2:['未平仓品种比率',false],
        },
        echartDataOne:'',
        echartDatabottom:'',
        topEcharts:[

        ],
        bottomEharts:[
          {
            color:'#d92d2b',
            value:'',
          },
          { 
            color:'#25b961',
            value:'',
          }
        ]
      }
    },
    created(){
      // 传参控制显示顶部和底部
      this.$emit('controlShow', false);
      // 持仓比例数据
      datacenterGoldSilv().then(res=>{
        if(res.IsSuccess){
          this.topEcharts = res.Message;
        }
      }).catch(err=>{
        console.log(err);
      });
      // 未平仓品种比率
      datacenterGoldSilverScale().then(res=>{
        if(res.IsSuccess){
          this.bottomEharts[0].value = res.Message[0].GoldScale.toString();
          this.bottomEharts[1].value = res.Message[0].SilverScale.toString();
          this.echartDatabottom = res.Message[0].UpdateTime;
        }
      }).catch(err=>{
        console.log(err);
      })
    },
    methods:{

    }
  }
</script>

<style lang="less" scoped>
  .Details{
    width: 100%;
    .bigImg{
      width: 100%;
      height: 600px;
      background: url('../../static/details/banner.jpg') no-repeat;
      background-size: cover;
    }
    .dTop{
      width: 1416px;
      margin: 90px auto 100px;
      .titP{
        width: 100%;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        color: #343434;
        box-sizing: border-box;
        padding-left: 30px;
        span{
          width: 100%;
          line-height: 24px;
        }
      }
      .bigBox{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 60px;
        .echartsBox{
          width: 49%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          box-sizing: border-box;
          padding-bottom: 10px;
          box-shadow:0px 0px 95px 0px rgba(43, 53, 105, 0.1);
          border-radius: 15px;
          border-radius: 15px;
          img{
            width: 100%;
          }
          .sjBox{
            width: 100%;
            height: 200px;
          }
          p{
            font-size: 14px;
            color: #999999;
            margin-top: 10px;
          }
        }
      }
    }
  }
  @media screen and (max-width: 500px) {
    .Details{
      width: 100%;
      .bigImg{
        width: 100%;
        height: 450px;
        background: url('../../static/index/wapImg/detailsBanner.jpg') no-repeat;
        background-size: cover;
      }
      .dTop{
        width: 100%;
        margin: 30px auto 50px;
        .titP{
          padding: 0;
          width: 97%;
          margin: 0 auto;
          span{
            font-size: 14px;
          }
        }
        .bigBox{
          margin-top: 30px;
          flex-direction: column;
          .echartsBox{
            width: 97%;
            .sjBox{
              .pastBox{
                width: 90%;
                margin: 0 auto;
              }
            }
          }
          .echartsBox:nth-child(1){
            margin-bottom: 20px;
          }
        }
      }
    }
  }
</style>